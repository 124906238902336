<template>
    <div v-if="dataReport">            
      <Pivot :report="dataReport" toolbar :key="'pivot_'+forceUpdate" />
    </div>  
</template>

<script> 
  import {Pivot} from 'vue-webdatarocks';
  import 'webdatarocks/webdatarocks.css';
  
  export default {   
    props:{
      dataJson: {
        type: [String, Object],
        default: null,
      },
    },       
    components: {
      Pivot,
    },
    data() {
      return {                
        dataReport: '',
        forceUpdate: 0,
      }
    },
    mounted() {     
      this.dataReport = this.dataJson                  
    }, 
    watch: {
      'dataJson': function(newVal, oldVal) {      
        this.dataReport = this.dataJson
        this.setForceUpdate()        
      },      
    },
    methods: {
      setForceUpdate() {                
        this.forceUpdate = this.forceUpdate + 1;        
      },
    }   
  }
</script>